import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValidIBAN } from 'ibantools';

/**
 * A validator function that returns null if the IBAN is valid, otherwise an object with an 'iban' property set to true.
 */
export function ValidateIBAN(control: AbstractControl): ValidationErrors | null {
    const iban = control.value;
    return isValidIBAN(iban) ? null : { iban: true };
}
