import { inject, Injectable } from '@angular/core';
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions';
import {
    CoachCentralCoachingUnitCreateData,
    CoachCentralCoachingUnitDeleteData,
    CoachCentralCoachingUnitMailSendData,
    CoachCentralCoachingUnitsReadData,
    CoachCentralCoachingUnitsReadResponse,
    CoachCentralCoachingUnitUpdateData,
    CoachCentralLeadHelpMailSendData,
    CoachCentralLeadReadData,
    CoachCentralLeadReadResponse,
    CoachCentralLeadsReadResponse,
    CoachCentralLeadUpdateData,
    CoachCentralOrdersReadResponse,
    CoachCentralType,
    ProfileCoachCentral,
    region,
} from '@lead-in/core';
import { getApp } from 'firebase/app';
import { NotificationService } from '../notification';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private readonly functions = getFunctions(getApp(), region);
    private readonly notifications = inject(NotificationService);

    private readonly functionName = 'coachCentral';

    readonly profileRead = this.createApiCall(
        'profileRead',
        httpsCallable<void, ProfileCoachCentral>(this.functions, this.functionName)
    );

    readonly profileUpdate = this.createApiCall(
        'profileUpdate',
        httpsCallable<ProfileCoachCentral, void>(this.functions, this.functionName)
    );

    readonly leadsReads = this.createApiCall(
        'leadsRead',
        httpsCallable<void, CoachCentralLeadsReadResponse>(this.functions, this.functionName)
    );

    readonly leadRead = this.createApiCall(
        'leadRead',
        httpsCallable<CoachCentralLeadReadData, CoachCentralLeadReadResponse>(this.functions, this.functionName)
    );

    readonly leadUpdate = this.createApiCall(
        'leadUpdate',
        httpsCallable<CoachCentralLeadUpdateData, void>(this.functions, this.functionName)
    );

    readonly leadHelpMailSend = this.createApiCall(
        'leadHelpMailSend',
        httpsCallable<CoachCentralLeadHelpMailSendData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitsRead = this.createApiCall(
        'coachingUnitsRead',
        httpsCallable<CoachCentralCoachingUnitsReadData, CoachCentralCoachingUnitsReadResponse>(
            this.functions,
            this.functionName
        )
    );

    readonly coachingUnitCreate = this.createApiCall(
        'coachingUnitCreate',
        httpsCallable<CoachCentralCoachingUnitCreateData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitUpdate = this.createApiCall(
        'coachingUnitUpdate',
        httpsCallable<CoachCentralCoachingUnitUpdateData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitDelete = this.createApiCall(
        'coachingUnitDelete',
        httpsCallable<CoachCentralCoachingUnitDeleteData, void>(this.functions, this.functionName)
    );

    readonly coachingUnitMailSend = this.createApiCall(
        'coachingUnitMailSend',
        httpsCallable<CoachCentralCoachingUnitMailSendData, void>(this.functions, this.functionName)
    );

    readonly ordersRead = this.createApiCall(
        'ordersRead',
        httpsCallable<void, CoachCentralOrdersReadResponse>(this.functions, this.functionName)
    );

    /**
     * Creates a callable API function that returns a promise with the response data.
     *
     * @param {CoachCentralType} requestType - The request type.
     * @param {HttpsCallable<RequestData, ResponseData>} callable - The callable API function.
     * @returns {(data: RequestData) => Promise<ResponseData>} A function that takes request data and returns a promise with the response data.
     */
    private createApiCall<RequestData = unknown, ResponseData = unknown>(
        requestType: CoachCentralType,
        callable: HttpsCallable<RequestData, ResponseData>
    ): (data: RequestData) => Promise<ResponseData> {
        return (data: RequestData) => {
            return new Promise<ResponseData>((resolve, reject) => {
                callable({ requestType, ...data })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        this.notifications.error(error);
                        reject(error);
                    });
            });
        };
    }
}
