import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { ApiService } from '@app/shared';
import { CoachCentralDetailLead, CoachingUnit, getNumDate, ProfileCoachCentral } from '@lead-in/core';

export interface ProcessViewDialogData {
    lead: CoachCentralDetailLead;
}

interface CoachingUnitExtended extends Pick<CoachingUnit, 'date' | 'documentation' | 'unitCount'> {
    unitCountTotal: string;
    showSignatureCoach: boolean;
    showSignatureLead: boolean;
}

@Component({
    selector: 'app-process-view',
    templateUrl: './process-view.component.html',
    styleUrl: './process-view.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatProgressSpinnerModule, MatTableModule],
})
export class ProcessViewComponent implements OnInit {
    private readonly api = inject(ApiService);
    private readonly dialogRef = inject(MatDialogRef<ProcessViewComponent>);

    readonly data = inject<ProcessViewDialogData>(MAT_DIALOG_DATA);
    readonly coachingUnitsExtended = signal<CoachingUnitExtended[]>([]);
    readonly profile = signal<ProfileCoachCentral | null>(null);
    readonly loadingCoachingUnits = signal(false);
    readonly loadingProfile = signal(false);

    displayedColumns: string[] = [
        'date',
        'documentation',
        'unitCount',
        'unitCountTotal',
        'signatureCoach',
        'signatureLead',
    ];

    ngOnInit(): void {
        this.getCoachingUnits();
        this.loadProfile();
    }

    getCoachingUnits(): void {
        this.loadingCoachingUnits.set(true);
        this.api
            .coachingUnitsRead({ leadId: this.data.lead.id })
            .then((response) => {
                const coachingUnits = response.coachingUnits.toSorted((a, b) => a.numDate - b.numDate);
                const coachingUnitsExtended: CoachingUnitExtended[] = [];

                const todayNumDate = getNumDate();

                let currentUnitCount = 0;
                for (const coachingUnit of coachingUnits) {
                    if (coachingUnit.numDate <= todayNumDate) {
                        currentUnitCount += coachingUnit.unitCount;
                        const coachingUnitExtended: CoachingUnitExtended = {
                            ...coachingUnit,
                            date: new Date(coachingUnit.date).toISOString().split('T')[0],
                            unitCountTotal: `${currentUnitCount} von ${this.data.lead.maxNumOfCoachingUnits}`,
                            showSignatureCoach: coachingUnit.state !== 'new',
                            showSignatureLead:
                                coachingUnit.state === 'accepted' || coachingUnit.state === 'ill_within_time',
                        };
                        coachingUnitsExtended.push(coachingUnitExtended);
                    }
                }

                this.coachingUnitsExtended.set(coachingUnitsExtended);
            })
            .finally(() => {
                this.loadingCoachingUnits.set(false);
            });
    }

    loadProfile(): void {
        this.loadingProfile.set(true);
        this.api
            .profileRead()
            .then((data) => {
                this.profile.set(data);
            })
            .finally(() => {
                this.loadingProfile.set(false);
            });
    }

    back(): void {
        this.dialogRef.close();
    }

    print(): void {
        window.print();
    }
}
