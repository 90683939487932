import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { CoachCentralListLead } from '@lead-in/core';

@Component({
    selector: 'app-lead-list',
    templateUrl: './lead-list.component.html',
    styleUrl: './lead-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatListModule, RouterModule],
})
export class LeadListComponent {
    readonly leads = input.required<CoachCentralListLead[] | null>();
}
