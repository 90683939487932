import {
    ApplicationConfig,
    EnvironmentProviders,
    LOCALE_ID,
    provideExperimentalZonelessChangeDetection,
    Provider,
} from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';

export const APP_PROVIDERS: (EnvironmentProviders | Provider)[] = [
    provideExperimentalZonelessChangeDetection(),
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: NativeDateAdapter, deps: [MAT_DATE_LOCALE] },
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions()),
];

export const appConfig: ApplicationConfig = {
    providers: APP_PROVIDERS,
};
