import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CoachCentralFormField, isComboCoachCentralFormField } from '@lead-in/core';

/**
 * Builds a FormGroup from an array of CoachCentralFormField objects.
 *
 * @param formFields An array of CoachCentralFormField objects.
 * @returns A FormGroup instance representing the provided form fields.
 */
export function buildFormGroup(formFields: CoachCentralFormField[]): FormGroup {
    return new FormGroup(
        formFields.reduce((fg, field) => {
            if (!field.isArray) {
                // => combination field `{'0': boolean|string|number; '1': boolean|string|number}`
                if (isComboCoachCentralFormField(field)) {
                    fg[field.id] = new FormGroup({
                        '0': new FormControl({ value: field.value?.['0'] ?? null, disabled: field.readonly }),
                        '1': new FormControl({ value: field.value?.['1'] ?? null, disabled: field.readonly }),
                    });
                }
                // => simple boolean|string|number field:
                else {
                    fg[field.id] = new FormControl({ value: field.value, disabled: field.readonly });
                }
            } else if (isComboCoachCentralFormField(field)) {
                // => combination field `{'0': boolean|string|number; '1': boolean|string|number}`
                const values = field.value ?? [];
                fg[field.id] = new FormArray(
                    values.map(
                        (value) =>
                            new FormGroup({
                                '0': new FormControl({ value: value?.['0'] ?? null, disabled: field.readonly }),
                                '1': new FormControl({ value: value?.['1'] ?? null, disabled: field.readonly }),
                            })
                    )
                );
            } else {
                // => simple boolean|string|number field:
                const values = field.value ?? [];
                fg[field.id] = new FormArray(
                    values.map((value) => new FormControl({ value, disabled: field.readonly }))
                );
            }
            return fg;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, {} as any) || {}
    );
}
