<ng-container [formGroup]="form()">
    <ng-container [formArrayName]="field().id">
        @for (control of controls(); track $index) {
            <div class="form-field-container">
                @switch (field().type) {
                    @case (FormFieldType.Combobox) {
                        <mat-form-field>
                            <mat-label>{{ field().name }}</mat-label>
                            <input
                                matInput
                                [readOnly]="field().readonly"
                                [formControl]="control"
                                [matAutocomplete]="auto"
                            />
                            <mat-autocomplete #auto="matAutocomplete">
                                @for (option of field().options; track $index) {
                                    <mat-option [value]="option">{{ option }}</mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    }
                    @case (FormFieldType.ComboboxRatingCombo) {
                        <div
                            class="row g-o"
                            [formGroupName]="$index"
                        >
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="0"
                                        [matAutocomplete]="auto"
                                    />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        @for (option of field().options; track $index) {
                                            <mat-option [value]="option">{{ option }}</mat-option>
                                        }
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                                    <mat-select formControlName="1">
                                        <mat-option [value]="0.2">1 Stern</mat-option>
                                        <mat-option [value]="0.4">2 Sterne</mat-option>
                                        <mat-option [value]="0.6">3 Sterne</mat-option>
                                        <mat-option [value]="0.8">4 Sterne</mat-option>
                                        <mat-option [value]="1">5 Sterne</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    }
                    @case (FormFieldType.ComboboxSelectCombo) {
                        <div
                            class="row g-o"
                            [formGroupName]="$index"
                        >
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="0"
                                        [matAutocomplete]="auto"
                                    />
                                    <mat-autocomplete #auto="matAutocomplete">
                                        @for (option of field().options; track $index) {
                                            <mat-option [value]="option">{{ option }}</mat-option>
                                        }
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                                    <mat-select formControlName="1">
                                        @for (option of field().options; track $index) {
                                            <mat-option [value]="option">{{ option }}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    }
                    @case (FormFieldType.Date) {
                        <mat-form-field>
                            <mat-label>{{ field().name }}</mat-label>
                            <input
                                matInput
                                [matDatepicker]="picker"
                                [formControl]="control"
                                [readOnly]="field().readonly"
                            />
                            <mat-datepicker-toggle
                                matIconSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    }
                    @case (FormFieldType.Integer) {
                        <mat-form-field>
                            <mat-label>{{ field().name }}</mat-label>
                            <input
                                matInput
                                [formControl]="control"
                                type="number"
                                step="1"
                                [readOnly]="field().readonly"
                            />
                        </mat-form-field>
                    }
                    @case (FormFieldType.Select) {
                        <mat-form-field>
                            <mat-label>{{ field().name }}</mat-label>
                            <mat-select [formControl]="control">
                                @for (option of field().options; track $index) {
                                    <mat-option [value]="option">{{ option }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                    @case (FormFieldType.SelectRatingCombo) {
                        <div
                            class="row g-o"
                            [formGroupName]="$index"
                        >
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                                    <mat-select formControlName="0">
                                        @for (option of field().options; track $index) {
                                            <mat-option [value]="option">{{ option }}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                                    <mat-select formControlName="1">
                                        <mat-option [value]="0.2">1 Stern</mat-option>
                                        <mat-option [value]="0.4">2 Sterne</mat-option>
                                        <mat-option [value]="0.6">3 Sterne</mat-option>
                                        <mat-option [value]="0.8">4 Sterne</mat-option>
                                        <mat-option [value]="1">5 Sterne</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    }
                    @case (FormFieldType.SelectTextCombo) {
                        <div
                            class="row g-0"
                            [formGroupName]="$index"
                        >
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                                    <mat-select formControlName="0">
                                        @for (option of field().options; track $index) {
                                            <mat-option [value]="option">{{ option }}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="1"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    }
                    @case (FormFieldType.Switch) {
                        <mat-slide-toggle [formControl]="control">
                            {{ field().name }}
                        </mat-slide-toggle>
                    }
                    @case (FormFieldType.Text) {
                        <mat-form-field>
                            <mat-label>{{ field().name }}</mat-label>
                            <input
                                matInput
                                [formControl]="control"
                                [readOnly]="field().readonly"
                            />
                        </mat-form-field>
                    }
                    @case (FormFieldType.TextRatingCombo) {
                        <div
                            class="row g-0"
                            [formGroupName]="$index"
                        >
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="0"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                                    <mat-select formControlName="1">
                                        <mat-option [value]="0.2">1 Stern</mat-option>
                                        <mat-option [value]="0.4">2 Sterne</mat-option>
                                        <mat-option [value]="0.6">3 Sterne</mat-option>
                                        <mat-option [value]="0.8">4 Sterne</mat-option>
                                        <mat-option [value]="1">5 Sterne</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    }
                    @case (FormFieldType.Textarea) {
                        <mat-form-field>
                            <mat-label>{{ field().name }}</mat-label>
                            <textarea
                                matInput
                                [formControl]="control"
                                [readOnly]="field().readonly"
                            ></textarea>
                        </mat-form-field>
                    }
                }
                <button
                    mat-icon-button
                    aria-label="Löschen"
                    (click)="remove($index)"
                    [disabled]="field().readonly"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        }
        <button
            mat-button
            (click)="add()"
            [disabled]="field().readonly"
        >
            {{ field().name }} hinzufügen
        </button>
    </ng-container>
</ng-container>
