<ng-container [formGroup]="form()">
    @switch (field().type) {
        @case (FormFieldType.Combobox) {
            <mat-form-field>
                <mat-label>{{ field().name }}</mat-label>
                <input
                    matInput
                    [formControlName]="field().id"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete">
                    @for (option of field().options; track $index) {
                        <mat-option [value]="option">{{ option }}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        }
        @case (FormFieldType.ComboboxRatingCombo) {
            <div
                class="row g-0"
                [formGroupName]="field().id"
            >
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                        <input
                            matInput
                            formControlName="0"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            @for (option of field().options; track $index) {
                                <mat-option [value]="option">{{ option }}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                        <mat-select formControlName="1">
                            <mat-option [value]="0.2">1 Stern</mat-option>
                            <mat-option [value]="0.4">2 Sterne</mat-option>
                            <mat-option [value]="0.6">3 Sterne</mat-option>
                            <mat-option [value]="0.8">4 Sterne</mat-option>
                            <mat-option [value]="1">5 Sterne</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        }
        @case (FormFieldType.ComboboxSelectCombo) {
            <div [formGroupName]="field().id">
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                        <input
                            matInput
                            formControlName="0"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            @for (option of field().options; track $index) {
                                <mat-option [value]="option">{{ option }}</mat-option>
                            }
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                        <mat-select formControlName="1">
                            @for (option of field().options; track $index) {
                                <mat-option [value]="option">{{ option }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        }
        @case (FormFieldType.Date) {
            <mat-form-field>
                <mat-label>{{ field().name }}</mat-label>
                <input
                    matInput
                    [matDatepicker]="picker"
                    [formControlName]="field().id"
                />
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        }
        @case (FormFieldType.Integer) {
            <mat-form-field>
                <mat-label>{{ field().name }}</mat-label>
                <input
                    matInput
                    [formControlName]="field().id"
                    type="number"
                    step="1"
                />
            </mat-form-field>
        }
        @case (FormFieldType.Select) {
            <mat-form-field>
                <mat-label>{{ field().name }}</mat-label>
                <mat-select [formControlName]="field().id">
                    @for (option of field().options; track $index) {
                        <mat-option [value]="option">{{ option }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        @case (FormFieldType.SelectRatingCombo) {
            <div
                class="row g-0"
                [formGroupName]="field().id"
            >
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                        <mat-select formControlName="0">
                            @for (option of field().options; track $index) {
                                <mat-option [value]="option">{{ option }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                        <mat-select formControlName="1">
                            <mat-option [value]="0.2">1 Stern</mat-option>
                            <mat-option [value]="0.4">2 Sterne</mat-option>
                            <mat-option [value]="0.6">3 Sterne</mat-option>
                            <mat-option [value]="0.8">4 Sterne</mat-option>
                            <mat-option [value]="1">5 Sterne</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        }
        @case (FormFieldType.SelectTextCombo) {
            <div
                class="row g-0"
                [formGroupName]="field().id"
            >
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                        <mat-select formControlName="0">
                            @for (option of field().options; track $index) {
                                <mat-option [value]="option">{{ option }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                        <input
                            matInput
                            formControlName="1"
                        />
                    </mat-form-field>
                </div>
            </div>
        }
        @case (FormFieldType.Switch) {
            <mat-slide-toggle [formControlName]="field().id">
                {{ field().name }}
            </mat-slide-toggle>
        }
        @case (FormFieldType.Text) {
            <mat-form-field>
                <mat-label>{{ field().name }}</mat-label>
                <input
                    matInput
                    [formControlName]="field().id"
                />
            </mat-form-field>
        }
        @case (FormFieldType.TextRatingCombo) {
            <div
                class="row g-0"
                [formGroupName]="field().id"
            >
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationLeftLabel || field().name }}</mat-label>
                        <input
                            matInput
                            formControlName="0"
                        />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>{{ field().combinationRightLabel || field().name }}</mat-label>
                        <mat-select formControlName="1">
                            <mat-option [value]="0.2">1 Stern</mat-option>
                            <mat-option [value]="0.4">2 Sterne</mat-option>
                            <mat-option [value]="0.6">3 Sterne</mat-option>
                            <mat-option [value]="0.8">4 Sterne</mat-option>
                            <mat-option [value]="1">5 Sterne</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        }
        @case (FormFieldType.Textarea) {
            <mat-form-field>
                <mat-label>{{ field().name }}</mat-label>
                <textarea
                    matInput
                    [formControlName]="field().id"
                ></textarea>
            </mat-form-field>
        }
    }
</ng-container>
