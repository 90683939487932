import { FirebaseConfig } from '@app/shared';
import { environment } from '@env/environment';
import { ENV_PROD_PROJECT_ID } from '@lead-in/core';

/**
 * Is the production flag set?
 */
export function envProduction(): boolean {
    return environment.production;
}

/**
 * Get the current firebase config object.
 */
export function envFirebaseConfig(): FirebaseConfig {
    return environment.firebaseConfig;
}

/**
 * Get the current project ID (Fallback is 'N/A').
 */
export function envFirebaseConfigProjectId(): string {
    return envCheckFirebaseConfig() ? envFirebaseConfig().projectId : 'N/A';
}

/**
 * Check if the current project ID is set.
 */
export function envCheckFirebaseConfig(): boolean {
    return envFirebaseConfig()?.projectId?.trim().length > 0;
}

/**
 * Get the auth domain url.
 */
export function envFirebaseConfigAuthDomain(): string {
    return envFirebaseConfig().authDomain;
}

/**
 * Check if the product ID is the product ID of the production system.
 */
export function envFirebaseConfigIsProdProjectId(): boolean {
    return envFirebaseConfigProjectId() === ENV_PROD_PROJECT_ID;
}
