import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ApiService } from '@app/shared/api/api.service';
import {
    CoachCentralCoachingUnitCreateData,
    CoachCentralCoachingUnitUpdateData,
    CoachCentralDetailLead,
    COACHING_UNIT_FORMAT_LABELS,
    COACHING_UNIT_FORMATS,
    COACHING_UNIT_TYPE_LABELS,
    CoachingUnit,
    CoachingUnitChange,
    CoachingUnitFormat,
    CoachingUnitState,
    CoachingUnitVerificationType,
    EDITABLE_STATES,
    FINISHED_STATES,
    getDateAndNumDate,
    getNumDate,
} from '@lead-in/core';

@Component({
    selector: 'app-coaching-unit-list',
    templateUrl: './coaching-unit-list.component.html',
    styleUrl: './coaching-unit-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatOption,
        MatProgressSpinnerModule,
        MatSelect,
        MatTooltipModule,
        ReactiveFormsModule,
        TextFieldModule,
    ],
})
export class CoachingUnitListComponent implements OnInit {
    private readonly api = inject(ApiService);

    readonly lead = input.required<CoachCentralDetailLead>();

    readonly coachingUnits = signal<CoachingUnit[]>([]);
    readonly coachingUnitsOther = signal<CoachingUnit[]>([]);
    readonly coachingUnitsFinal = signal<CoachingUnit[]>([]);
    readonly loading = signal(false);

    readonly CoachingUnitVerificationType = CoachingUnitVerificationType;
    readonly EDITABLE_STATES = EDITABLE_STATES;
    readonly FINISHED_STATES = FINISHED_STATES;
    readonly COACHING_UNIT_TYPE_LABELS = Object.values(COACHING_UNIT_TYPE_LABELS);

    readonly unitChange = output<void>();

    readonly form = new FormGroup({
        date: new FormControl(''),
        unitCount: new FormControl(1),
        format: new FormControl(null),
    });
    readonly formFormat: CoachingUnitFormat | null = null;

    readonly formatOptions = COACHING_UNIT_FORMATS.map((coachingUnitFormat) => ({
        label: COACHING_UNIT_FORMAT_LABELS[coachingUnitFormat],
        value: coachingUnitFormat,
    }));

    todayNumDate = getNumDate();

    ngOnInit(): void {
        this.getCoachingUnits();
    }

    getCoachingUnits(): void {
        this.loading.set(true);
        this.api
            .coachingUnitsRead({ leadId: this.lead().id })
            .then((response) => {
                const sortedCoachingUnits = response.coachingUnits.toSorted((a, b) => a.numDate - b.numDate);
                this.coachingUnits.set(sortedCoachingUnits.filter((unit) => unit.type === 'coaching'));
                this.coachingUnitsOther.set(sortedCoachingUnits.filter((unit) => unit.type === 'other'));
                this.coachingUnitsFinal.set(sortedCoachingUnits.filter((unit) => unit.type === 'final'));
            })
            .finally(() => {
                this.loading.set(false);
            });
    }

    createCoachingUnit(): void {
        this.loading.set(true);
        const date = this.parseDateFromDatePicker(this.form.value.date as unknown as Date);
        const format = this.form.value.format ? (this.form.value.format as CoachingUnitFormat) : null;
        const coachingUnit: CoachCentralCoachingUnitCreateData = {
            leadId: this.lead().id,
            customerId: this.lead().customerId,
            date,
            type: 'coaching',
            unitCount: this.form.value.unitCount!,
            format,
            documentation: '',
        };
        this.form.disable();
        this.api
            .coachingUnitCreate(coachingUnit)
            .then(() => {
                this.getCoachingUnits();
                this.unitChange.emit();
            })
            .finally(() => {
                this.form.reset({
                    date: '',
                    unitCount: 1,
                    format: null,
                });
                this.form.enable();
                this.loading.set(false);
            });
    }

    updateCoachingUnit(
        id: string,
        dateString: string,
        unitCount: string,
        format: CoachingUnitFormat,
        documentation: string
    ): void {
        this.loading.set(true);
        const date = this.parseDateFromDatePicker(new Date(dateString));

        this.apiUpdateCoachingUnit({
            id,
            // language server won't recognize that matInput with matDatepicker directive will hold/emit `Date` type instead of string
            date,
            unitCount: Number(unitCount),
            format,
            documentation,
        });
    }

    deleteCoachingUnit(id: string): void {
        this.loading.set(true);
        this.api
            .coachingUnitDelete({ id })
            .then(() => {
                this.getCoachingUnits();
                this.unitChange.emit();
            })
            .finally(() => {
                this.loading.set(false);
            });
    }

    sendVerificationMail(id: string, type: CoachingUnitVerificationType): void {
        this.loading.set(true);
        this.api
            .coachingUnitMailSend({ id, type })
            .then(() => {
                this.getCoachingUnits();
            })
            .finally(() => {
                this.loading.set(false);
            });
    }

    async setIllOutsideTime(coachingUnit: CoachingUnit): Promise<void> {
        this.loading.set(true);
        const { date, numDate } = getDateAndNumDate();
        const state: CoachingUnitState = 'ill_outside_time';
        const stateChanges: CoachingUnitChange[] = [
            ...coachingUnit.stateChanges,
            {
                state,
                date,
                numDate,
                uid: null,
            },
        ];

        this.apiUpdateCoachingUnit({
            id: coachingUnit.id,
            date: coachingUnit.date,
            unitCount: coachingUnit.unitCount,
            format: coachingUnit.format,
            documentation: coachingUnit.documentation,
            state: 'ill_outside_time',
            stateChanges,
        });
    }

    /**
     * Private helper function to update a coaching unit.
     *
     * Calls the callable function `coachCentralCoachingUnitUpdate` with the provided `coachingUnit` data.
     *
     * After a successful update, calls `getCoachingUnits` to update the list of coaching units, and emits the `unitChange` event.
     */
    private apiUpdateCoachingUnit(coachingUnit: CoachCentralCoachingUnitUpdateData): void {
        this.loading.set(true);
        this.api
            .coachingUnitUpdate(coachingUnit)
            .then(() => {
                this.getCoachingUnits();
                this.unitChange.emit();
            })
            .finally(() => {
                this.loading.set(false);
            });
    }

    /**
     * Take a Date from a MatDatepicker, and return a new string in ISO date format, but without the time part.
     * The timezone offset of the local machine is taken into account.
     * @param dateFromString is the Date to parse
     * @returns a string in the format "YYYY-MM-DD"
     */
    private parseDateFromDatePicker(dateFromString: Date) {
        const timezoneOffset = dateFromString.getTimezoneOffset() * 60_000;
        const isoDate = new Date(dateFromString.getTime() - timezoneOffset).toISOString();
        return isoDate.split('T')[0];
    }
}
