import { Routes } from '@angular/router';
import {
    LeadDetailsComponent,
    LoginComponent,
    MainComponent,
    PageNotFoundComponent,
    ProfileComponent,
} from '@app/pages';
import { AuthGuard } from '@app/shared';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/main',
    },
    {
        path: 'main',
        component: MainComponent,
        canActivate: [AuthGuard],
    },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    {
        path: 'lead/:customerId/details/:leadId',
        component: LeadDetailsComponent,
    },
    { path: 'login', component: LoginComponent },
    { path: '**', component: PageNotFoundComponent },
];
