import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

const STANDARD_DURATION = 5_000;
const ERROR_DURATION = 30_000;

const OK = 'Ok';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private readonly snackbar = inject(MatSnackBar);

    /**
     * Dispatches an information notification with the specified message, action, and configuration.
     *
     * @param message The message to be displayed in the notification.
     * @param mergedConfig Optional configuration for the information notification, including the action label and duration.
     */
    info(
        message: string,
        mergedConfig: { action: string; config: MatSnackBarConfig } = {
            action: OK,
            config: { duration: STANDARD_DURATION },
        }
    ) {
        this.notify(message, mergedConfig.action, mergedConfig.config);
    }

    /**
     * Dispatches an error notification with the specified message, action, and configuration.
     *
     * @param message The error message to be displayed in the notification.
     * @param mergedConfig Optional configuration for the error notification, including the action label and duration.
     */
    error(
        message: string,
        mergedConfig: { action: string; config: MatSnackBarConfig } = {
            action: OK,
            config: { duration: ERROR_DURATION },
        }
    ) {
        this.notify(message, mergedConfig.action, mergedConfig.config);
    }

    /**
     * Dispatches a notification with the specified message, action, and configuration.
     *
     * @param message The message to be displayed in the notification.
     * @param action The label for the action button in the notification.
     * @param config Additional configuration options for the notification.
     */
    private notify(message: string, action: string, config: MatSnackBarConfig) {
        this.snackbar.open(message, action, config);
    }
}
