@if (leads() === null) {
    <p>Klicken Sie auf "Neu laden" um Daten zu laden</p>
} @else {
    <mat-list>
        @for (lead of leads(); track lead.id) {
            <mat-list-item>
                <div matListItemTitle>{{ lead.fullName }}</div>
                <span matListItemLine
                    >Unterrichtseinheiten: {{ lead.numOfCoachingUnits }} von {{ lead.maxNumOfCoachingUnits }}</span
                >
                <div matListItemMeta>
                    <a
                        mat-icon-button
                        [routerLink]="['/lead', lead.customerId, 'details', lead.id]"
                    >
                        <mat-icon>edit</mat-icon>
                    </a>
                </div>
            </mat-list-item>
        } @empty {
            <mat-list-item>Noch keine Vorgänge</mat-list-item>
        }
    </mat-list>
}
