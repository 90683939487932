import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { CoachCentralOrder, COACHING_UNIT_STATE_LABELS } from '@lead-in/core';

@Component({
    selector: 'app-order-list',
    templateUrl: './order-list.component.html',
    styleUrl: './order-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatListModule, MatTableModule, RouterModule],
})
export class OrderListComponent {
    readonly labels = COACHING_UNIT_STATE_LABELS as unknown as Record<string, string>;
    readonly orders = input.required<CoachCentralOrder[] | null>();
    readonly displayedColumns: (keyof CoachCentralOrder)[] = [
        'orderNumber',
        'customerNumber',
        'date',
        'unitCount',
        'state',
    ];
}
