<div class="row">
    @for (field of fields(); track field.id) {
        <div
            [class.col-sm-6]="!comboTypes.includes(field.type)"
            [class.col-12]="comboTypes.includes(field.type)"
            [class.col-lg-4]="!comboTypes.includes(field.type)"
        >
            @if (!field.isArray) {
                <app-dynamic-form-control
                    [form]="form()"
                    [field]="field"
                ></app-dynamic-form-control>
            } @else {
                @if (isInsideCategory()) {
                    <h4>{{ field.arrayHeading || field.name }}</h4>
                } @else {
                    <h3>{{ field.arrayHeading || field.name }}</h3>
                }
                <app-dynamic-form-array
                    [form]="form()"
                    [field]="field"
                ></app-dynamic-form-array>
            }
        </div>
    }
</div>
