import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormArrayComponent, DynamicFormControlComponent } from '@app/components';
import { CoachCentralFormField, CombinationFieldTypes } from '@lead-in/core';

@Component({
    selector: 'app-form-field-list',
    templateUrl: './form-field-list.component.html',
    styleUrl: './form-field-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DynamicFormArrayComponent, DynamicFormControlComponent],
})
export class FormFieldListComponent {
    readonly comboTypes = CombinationFieldTypes;
    readonly form = input.required<FormGroup>();
    readonly fields = input.required<CoachCentralFormField[]>();
    /** Whether the list is inside a category. This will result in a heading to have `<h4>` instead `<h3>` */
    readonly isInsideCategory = input(false);
}
