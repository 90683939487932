<div class="row">
    <div class="col"></div>
    <div class="col-auto">
        @if (loading()) {
            <div class="spinner"><mat-spinner></mat-spinner></div>
        } @else {
            <h1>Bitte anmelden!</h1>
            <button
                mat-flat-button
                (click)="login()"
            >
                Anmelden
            </button>
        }
    </div>
    <div class="col"></div>
</div>
