import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { ValidateIBAN } from '@app/shared';
import { ApiService } from '@app/shared/api/api.service';
import { CostsPerUnitObject, PROFILE_COACH_CENTRAL_LABELS, sortCostsPerUnitOrProductsList } from '@lead-in/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DatePipe,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
    ],
})
export class ProfileComponent {
    private readonly router = inject(Router);
    private readonly api = inject(ApiService);

    readonly labels = PROFILE_COACH_CENTRAL_LABELS;

    readonly loading = signal(false);
    readonly saving = signal(false);

    readonly form = new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        streetAddress: new FormControl('', [Validators.required]),
        postalCode: new FormControl('', [Validators.required, Validators.pattern(/^\d{5}$/)]),
        locality: new FormControl('', [Validators.required]),
        iban: new FormControl('', [Validators.required, ValidateIBAN]),
    });
    costsPerUnitList: CostsPerUnitObject[] = [];

    constructor() {
        this.loadProfile();
    }

    loadProfile(): void {
        this.loading.set(true);
        this.api
            .profileRead()
            .then((data) => {
                this.form.setValue({
                    firstName: data.firstName ?? null,
                    lastName: data.lastName ?? null,
                    streetAddress: data.streetAddress ?? null,
                    postalCode: data.postalCode ?? null,
                    locality: data.locality ?? null,
                    iban: data.iban ?? null,
                });
                this.costsPerUnitList = sortCostsPerUnitOrProductsList(data.costsPerUnitList || []);
            })
            .finally(() => {
                this.loading.set(false);
            });
    }

    saveProfile(): void {
        this.saving.set(true);
        this.api.profileUpdate({ ...this.form.value }).finally(() => {
            this.form.markAsPristine();
            this.saving.set(false);
        });
    }

    back(): void {
        this.router.navigate(['/main']);
    }
}
