<h1>
    <button
        mat-icon-button
        (click)="back()"
        aria-label="Zurück"
        matTooltip="Zurück"
    >
        <mat-icon>arrow_back</mat-icon>
    </button>
    Vorgangsdetails
    <ng-template [ngTemplateOutlet]="loadLead"></ng-template>
</h1>
@if (loading()) {
    <mat-spinner></mat-spinner>
} @else if (lead() === null) {
    <p>Keine Daten vorhanden</p>
} @else {
    <h2>Details</h2>
    <button
        mat-button
        (click)="sendLeadHelpMail(lead()!.customerId, lead()!.id)"
        [disabled]="sendingHelpMail()"
    >
        <mat-icon>help</mat-icon>
        Hilfe zu diesem Vorgang anfragen
    </button>

    <button
        mat-button
        (click)="openParticipantFileUrl(lead()!.participantFileUrl)"
        [disabled]="!lead()!.participantFileUrl.length || validParticipantFileUrl() === false"
    >
        <mat-icon>inventory-2</mat-icon>
        Teilnehmercoaching-Akte
    </button>
    @if (validParticipantFileUrl() === false) {
        <mat-error>Der Link für die Teilnehmercoaching-Akte ist nicht gültig.</mat-error>
    }
    <br /><br />

    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Stammdaten für {{ lead()!.fullName }}</mat-panel-title>
            </mat-expansion-panel-header>
            <app-form-field-list
                [form]="masterDataForm()"
                [fields]="lead()!.masterDataFields"
            ></app-form-field-list>

            @for (section of customerDataSections(); track section.id) {
                <h2>{{ section.name }}</h2>
                <app-form-field-list
                    [form]="section.form"
                    [fields]="lead()!.customerDataSections[$index].formFields"
                ></app-form-field-list>
            }

            @for (category of leadDataCategories(); track category.id; let categoryIndex = $index) {
                <h2>{{ category.name }}</h2>
                @for (section of category.sections; track section.id) {
                    <h3>{{ section.name }}</h3>
                    <app-form-field-list
                        [form]="section.form"
                        [fields]="lead()!.leadDataCategories[categoryIndex].formFieldSections[$index].formFields"
                        [isInsideCategory]="true"
                    ></app-form-field-list>
                }
            }

            <br />
            <ng-template [ngTemplateOutlet]="loadLead"></ng-template>
            <button
                mat-flat-button
                (click)="saveLead()"
                [disabled]="loading() || saving()"
            >
                Daten speichern
            </button>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Dateien ({{ lead()!.documents ? lead()!.documents.length : '0' }})</mat-panel-title>
            </mat-expansion-panel-header>
            @for (document of lead()!.documents; track document.storagePath) {
                <button
                    mat-flat-button
                    (click)="downloadDocument(document)"
                    [disabled]="!document.storagePath?.length"
                >
                    <mat-icon>description</mat-icon>
                    {{ document.filename }}
                </button>
                <br /><br />
            } @empty {
                <p>Keine Dateien vorhanden</p>
            }
        </mat-expansion-panel>
    </mat-accordion>

    <div class="row">
        <div class="col-auto">
            <h2>Termine</h2>
        </div>
        <div class="col open-button">
            <button
                mat-icon-button
                aria-label="Verlaufsprotokoll"
                matTooltip="Verlaufsprotokoll"
                [disabled]="loading()"
                (click)="openProcessView(lead()!)"
            >
                <mat-icon>receipt_long</mat-icon>
            </button>
        </div>
    </div>

    <p>
        Zeitraum:
        @if (lead()?.dateFrom) {
            {{ lead()?.dateFrom | date }}
        } @else {
            unbegrenzt
        }
        -
        @if (lead()?.dateTo) {
            {{ lead()?.dateTo | date }}
        } @else {
            unbegrenzt
        }
        <br />
        Unterrichtseinheiten: {{ lead()!.numOfCoachingUnits }} von
        {{ lead()!.maxNumOfCoachingUnits }}
    </p>
    <app-coaching-unit-list
        [lead]="lead()!"
        (unitChange)="getLead()"
    ></app-coaching-unit-list>
}

<ng-template #loadLead>
    <button
        mat-button
        (click)="getLead()"
        [disabled]="loading() || saving()"
    >
        Daten neu laden
    </button>
</ng-template>
