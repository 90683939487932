import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrl: './page-not-found.component.scss',
    standalone: true,
    imports: [MatButtonModule],
})
export class PageNotFoundComponent {
    private readonly router = inject(Router);

    back(): void {
        this.router.navigate(['/login']);
    }
}
