@for (coachingUnit of coachingUnits(); track coachingUnit.id) {
    <div class="row">
        <div class="col-6 col-md">
            <mat-form-field>
                <mat-label>Datum</mat-label>
                <input
                    #dateInput="matInput"
                    matInput
                    [matDatepicker]="picker"
                    [value]="coachingUnit.date"
                    [disabled]="loading() || coachingUnit.state === 'accepted'"
                />
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-6 col-md">
            <mat-form-field>
                <mat-label>Unterrichtseinheiten</mat-label>
                <input
                    #unitCountInput="matInput"
                    matInput
                    type="number"
                    min="1"
                    max="10"
                    step="1"
                    [value]="coachingUnit.unitCount"
                    [disabled]="loading() || coachingUnit.state === 'accepted'"
                />
            </mat-form-field>
        </div>
        <div class="col-md-auto pt-2">
            <button
                mat-icon-button
                aria-label="Löschen"
                matTooltip="Löschen"
                [disabled]="loading() || coachingUnit.state === 'accepted'"
                (click)="deleteCoachingUnit(coachingUnit.id)"
            >
                <mat-icon>delete</mat-icon>
            </button>
            <button
                mat-icon-button
                aria-label="Speichern"
                matTooltip="Speichern"
                [disabled]="loading() || coachingUnit.state === 'accepted'"
                (click)="
                    updateCoachingUnit(coachingUnit.id, dateInput.value, unitCountInput.value, documentationInput.value)
                "
            >
                <mat-icon>save</mat-icon>
            </button>
            <button
                mat-icon-button
                aria-label="Krank"
                matTooltip="Krank"
                [matMenuTriggerFor]="menuForIllness"
                [disabled]="loading() || coachingUnit.state !== 'new'"
            >
                <mat-icon>medical_services</mat-icon>
            </button>
            <mat-menu #menuForIllness="matMenu">
                <button
                    mat-menu-item
                    [disabled]="loading() || coachingUnit.state !== 'new'"
                    (click)="sendVerificationMail(coachingUnit.id, CoachingUnitVerificationType.IllWithinTime)"
                >
                    Versenden, krank innerhalb 24h
                </button>
                <button
                    mat-menu-item
                    [disabled]="loading() || coachingUnit.state !== 'new'"
                    (click)="setIllOutsideTime(coachingUnit)"
                >
                    Krank außerhalb 24h
                </button>
            </mat-menu>
            <button
                mat-icon-button
                aria-label="Versenden (Terminbestätigung)"
                matTooltip="Versenden (Terminbestätigung)"
                [disabled]="loading() || coachingUnit.state !== 'new' || coachingUnit.numDate > todayNumDate"
                (click)="sendVerificationMail(coachingUnit.id, CoachingUnitVerificationType.Default)"
            >
                <mat-icon>send</mat-icon>
            </button>
            <span [className]="coachingUnit.state">
                @switch (coachingUnit.state) {
                    @case ('new') {
                        <mat-icon matTooltip="Status: neu">hourglass_top</mat-icon>
                    }
                    @case ('sent') {
                        <mat-icon matTooltip="Status: versendet (Terminbestätigung)">mail</mat-icon>
                    }
                    @case ('accepted') {
                        <mat-icon matTooltip="Status: bestätigt">check</mat-icon>
                    }
                    @case ('rejected') {
                        <mat-icon matTooltip="Status: abgelehnt">error</mat-icon>
                    }
                    @case ('sent_ill_within_time') {
                        <mat-icon matTooltip="Status: versendet (krank innerhalb 24h)">mail</mat-icon>
                    }
                    @case ('ill_within_time') {
                        <mat-icon matTooltip="Status: krank (innerhalb 24h)">personal_injury</mat-icon>
                    }
                    @case ('ill_outside_time') {
                        <mat-icon matTooltip="Status: krank (außerhalb 24h)">personal_injury</mat-icon>
                    }
                }
            </span>
        </div>
        <div class="col-12">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title
                            >Gesprächsinhalte / Bewerbungsaktivitäten ({{
                                coachingUnit.documentation.trim().length ? 'befüllt' : 'leer'
                            }})</mat-panel-title
                        >
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <textarea
                            #documentationInput="matInput"
                            matInput
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            [value]="coachingUnit.documentation"
                            [disabled]="loading()"
                        ></textarea>
                    </mat-form-field>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    @if (!$last) {
        <br />
    }
} @empty {
    @if (loading()) {
        <mat-spinner></mat-spinner>
    } @else {
        <p>Noch keine Termine</p>
    }
}

<form
    [formGroup]="form"
    (ngSubmit)="createCoachingUnit()"
>
    <h3>Neuen Termin hinzufügen</h3>
    <div class="row">
        <div class="col">
            <mat-form-field>
                <mat-label>Datum</mat-label>
                <input
                    matInput
                    [matDatepicker]="picker"
                    formControlName="date"
                    required
                />
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field>
                <mat-label>Unterrichtseinheiten</mat-label>
                <input
                    matInput
                    type="number"
                    min="1"
                    max="10"
                    step="1"
                    formControlName="unitCount"
                    required
                />
            </mat-form-field>
        </div>
        <div class="col-auto pt-2">
            <button
                mat-button
                type="submit"
                [disabled]="form.invalid || loading()"
            >
                <mat-icon>add</mat-icon>
                Hinzufügen
            </button>
        </div>
    </div>
</form>
