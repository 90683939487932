import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { DynamicFormControlComponent } from '@app/components';
import { CoachCentralFormField, FormFieldType, isComboCoachCentralFormField } from '@lead-in/core';

@Component({
    selector: 'app-dynamic-form-array',
    standalone: true,
    templateUrl: './dynamic-form-array.component.html',
    styleUrl: './dynamic-form-array.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DynamicFormControlComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
    ],
})
export class DynamicFormArrayComponent {
    readonly FormFieldType = FormFieldType;
    readonly form = input.required<FormGroup>();
    readonly field = input.required<CoachCentralFormField>();
    readonly controls = computed(
        () => ((this.form().get(this.field().id) as FormArray).controls ?? []) as FormControl[]
    );

    remove(index: number): void {
        (this.form().get(this.field().id) as FormArray).removeAt(index);
    }

    add(): void {
        if (isComboCoachCentralFormField(this.field())) {
            console.log(this.form().get(this.field().id) as FormArray);
            (this.form().get(this.field().id) as FormArray).push(
                new FormGroup({ '0': new FormControl(null), '1': new FormControl(null) })
            );
        } else {
            (this.form().get(this.field().id) as FormArray).push(new FormControl(null));
        }
    }
}
