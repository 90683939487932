@if (orders() === null) {
    <p>Klicken Sie auf "Neu laden" um Daten zu laden</p>
} @else {
    <table
        mat-table
        [dataSource]="orders()!"
        class="mat-elevation-z1"
    >
        <ng-container matColumnDef="orderNumber">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Auftrags-ID
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.orderNumber }}
            </td>
        </ng-container>

        <ng-container matColumnDef="customerNumber">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Kd.-Nr.
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.customerNumber }}
            </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Termindatum
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.date | date }}
            </td>
        </ng-container>

        <ng-container matColumnDef="unitCount">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                UEs
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ element.unitCount }}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Terminstatus
            </th>
            <td
                mat-cell
                *matCellDef="let element"
            >
                {{ labels[element.state] }}
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
        ></tr>
    </table>
}
