import { enableProdMode } from '@angular/core';
import { envFirebaseConfig, envProduction } from '@app/shared';
import { initializeApp } from 'firebase/app';
import DOMPurify from 'dompurify';

/**
 * Configures and initializes the main application.
 */
/**
 * Configures and initializes the main application.
 *
 * @param testMode - A boolean flag indicating whether the application is in test mode.
 */
export function mainAppConfig(testMode = false): void {
    if (testMode === false) {
        // Configure the Trusted Types policy for the application
        configureTrustedTypesPolicy();
    }

    initializeApp(envFirebaseConfig());

    // Enable production mode for Angular if the environment is production
    if (envProduction()) {
        enableProdMode();
    }

    // Log the current mode (production or development)
    console.log('PROD-MODE:', envProduction());
}

/**
 * Configures the Trusted Types policy for the application.
 * This policy is used to define how to handle untrusted content, such as HTML, scripts, and URLs.
 * It utilizes DOMPurify to sanitize HTML content and allows scripts and URLs to pass through unchanged.
 */
function configureTrustedTypesPolicy(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowAsAny = window as any;

    if (windowAsAny.trustedTypes?.createPolicy) {
        windowAsAny.trustedTypes.createPolicy('default', {
            /**
             * Sanitizes HTML content using DOMPurify to prevent XSS attacks.
             * @param string The HTML string to be sanitized.
             * @returns The sanitized HTML string.
             */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            createHTML: (string: any) => DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: false }),
            /**
             * Allows script URLs to pass through unchanged.
             * @param string The script URL to be processed.
             * @returns The unchanged script URL.
             */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            createScriptURL: (string: any) => string,
            /**
             * Allows scripts to pass through unchanged.
             * @param string The script content to be processed.
             * @returns The unchanged script content.
             */
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            createScript: (string: any) => string,
        });
    }
}
