import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnDestroy, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ApiService, NotificationService } from '@app/shared';
import { CompleteAccountingCreditForCoach, convertNumberDateToTime } from '@lead-in/core';
import { map } from 'rxjs';
import fs from 'file-saver';

interface ExtendedCompleteAccountingCreditForCoach extends CompleteAccountingCreditForCoach {
    parsedFromDate: number;
    parsedToDate: number;
}

@Component({
    selector: 'app-accounting-credit-list',
    templateUrl: './accounting-credit-list.component.html',
    styleUrl: './accounting-credit-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatTableModule, RouterModule],
})
export class AccountingCreditListComponent implements AfterViewInit, OnDestroy {
    private readonly api = inject(ApiService);
    private readonly notificationService = inject(NotificationService);

    readonly accountingCredits = input.required<CompleteAccountingCreditForCoach[] | null>();
    readonly subscription = toObservable(this.accountingCredits)
        .pipe(
            map((accountingCredits) => {
                this.sortedAccountingCredits.data = (accountingCredits || []).map((accountingCredit) => ({
                    ...accountingCredit,
                    parsedFromDate: convertNumberDateToTime(accountingCredit.fromDate),
                    parsedToDate: convertNumberDateToTime(accountingCredit.toDate),
                }));
                return this.sortedAccountingCredits;
            })
        )
        .subscribe();

    readonly displayedColumns = ['createdAt', 'dateFromAndTo', 'link'];

    sortedAccountingCredits: MatTableDataSource<ExtendedCompleteAccountingCreditForCoach> =
        new MatTableDataSource<ExtendedCompleteAccountingCreditForCoach>([]);

    @ViewChild(MatSort) sort: MatSort | null = null;

    ngAfterViewInit(): void {
        if (this.sort) {
            this.sortedAccountingCredits.sort = this.sort;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * Downloads a file from the given storage path and triggers a download in the browser.
     *
     * @param storagePath The storage path of the file to be downloaded.
     * @param fileName The file name to be used for the downloaded file.
     *
     * @throws An error if the download process fails.
     */
    downloadDocument(storagePath: string, fileName: string): void {
        this.api
            .downloadFile(storagePath)
            .then((blob) => {
                fs.saveAs(blob, fileName);
            })
            .catch((error) => {
                this.notificationService.error(error?.message);
            });
    }
}
