<div class="container">
    <h1>Fehler 404</h1>
    <h2>Seite nicht gefunden</h2>
    <button
        mat-flat-button
        (click)="back()"
    >
        Zurück
    </button>
</div>
