<h1>
    <button
        mat-icon-button
        (click)="back()"
        aria-label="Zurück"
        matTooltip="Zurück"
    >
        <mat-icon>arrow_back</mat-icon>
    </button>
    Profil
</h1>
<br />
@if (loading()) {
    <mat-spinner></mat-spinner>
} @else {
    <form
        [formGroup]="form"
        (ngSubmit)="saveProfile()"
    >
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ labels['firstName'] }}</mat-label>
                    <input
                        matInput
                        formControlName="firstName"
                    />
                    @if (form.controls.firstName.errors?.['required']) {
                        <mat-error>{{ labels['firstName'] }} erforderlich</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ labels['lastName'] }}</mat-label>
                    <input
                        matInput
                        formControlName="lastName"
                    />
                    @if (form.controls.lastName.errors?.['required']) {
                        <mat-error>{{ labels['lastName'] }} erforderlich</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ labels['streetAddress'] }}</mat-label>
                    <input
                        matInput
                        formControlName="streetAddress"
                    />
                    @if (form.controls.streetAddress.errors?.['required']) {
                        <mat-error>{{ labels['streetAddress'] }} erforderlich</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ labels['postalCode'] }}</mat-label>
                    <input
                        matInput
                        formControlName="postalCode"
                    />
                    @if (form.controls.postalCode.errors?.['required']) {
                        <mat-error>{{ labels['postalCode'] }} erforderlich</mat-error>
                    } @else if (form.controls.postalCode.errors?.['pattern']) {
                        <mat-error>{{ labels['postalCode'] }} ungültig</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ labels['locality'] }}</mat-label>
                    <input
                        matInput
                        formControlName="locality"
                    />
                    @if (form.controls.locality.errors?.['required']) {
                        <mat-error>{{ labels['locality'] }} erforderlich</mat-error>
                    }
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{ labels['iban'] }}</mat-label>
                    <input
                        matInput
                        formControlName="iban"
                    />
                    @if (form.controls.iban.errors?.['required']) {
                        <mat-error>{{ labels['iban'] }} erforderlich</mat-error>
                    } @else if (form.controls.iban.errors?.['iban']) {
                        <mat-error>{{ labels['iban'] }} ungültig</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>
        <br />
        Stundensatz pro Unterrichtseinheit:
        @if (costsPerUnitList) {
            <ul>
                @for (costsPerUnitObject of costsPerUnitList; track $index) {
                    <li>
                        {{ costsPerUnitObject.costsPerUnit.toString().replace('.', ',') }}
                        €
                        @if (costsPerUnitObject.validFromDate) {
                            - Gültig ab
                            {{ costsPerUnitObject.validFromDate | date: 'dd.MM.yyyy' }}
                        }
                    </li>
                } @empty {
                    <li>Nicht hinterlegt</li>
                }
            </ul>
        }

        <br />
        <button
            mat-button
            (click)="loadProfile()"
            [disabled]="form.pristine || loading() || saving()"
        >
            Zurücksetzen
        </button>
        <button
            mat-flat-button
            type="submit"
            [disabled]="form.invalid || loading() || saving()"
        >
            Speichern
        </button>
    </form>
}
