<mat-dialog-content class="mat-typography">
    @if (!data.lead || loadingCoachingUnits() || loadingProfile()) {
        <mat-spinner></mat-spinner>
    } @else {
        <div class="row">
            <div class="col-12">
                <img
                    src="/logo-light.svg"
                    alt="GFN Logo"
                    height="40"
                    width="106"
                    title="GFN Logo"
                />
            </div>
        </div>
        <h2>Coachingverlauf und Stundennachweis</h2>
        @if (coachingUnitsExtended().length > 0) {
            <table
                mat-table
                [dataSource]="coachingUnitsExtended()"
                class="mat-elevation-z8"
            >
                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        Datum
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        {{ element.date | date }}
                    </td>
                </ng-container>

                <!-- Documentation Column -->
                <ng-container matColumnDef="documentation">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        Gesprächsinhalte / Bewerbungsaktivitäten
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        {{ element.documentation }}
                    </td>
                </ng-container>

                <!-- Format Column -->
                <ng-container matColumnDef="format">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        Format
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        {{ showCoachingUnitFormatLabel(element.format) }}
                    </td>
                </ng-container>

                <!-- UnitCount Column -->
                <ng-container matColumnDef="unitCount">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        UE
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        {{ element.unitCount }}
                    </td>
                </ng-container>

                <!-- UnitCountTotal Column -->
                <ng-container matColumnDef="unitCountTotal">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        UE - Gesamt
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        {{ element.unitCountTotal }}
                    </td>
                </ng-container>

                <!-- SignatureCoach Column -->
                <ng-container matColumnDef="signatureCoach">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        Unterschrift Coach
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        <i>{{ element.showSignatureCoach ? profile()?.firstName + ' ' + profile()?.lastName : '' }}</i>
                    </td>
                </ng-container>

                <!-- SignatureLead Column -->
                <ng-container matColumnDef="signatureLead">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                    >
                        Unterschrift Teilnehmende
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let element"
                    >
                        <i>{{ element.showSignatureLead ? data.lead.fullName : '' }}</i>
                    </td>
                </ng-container>

                <ng-container matColumnDef="header-first-row-first-group">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        [style.text-align]="'left'"
                        [attr.colspan]="2"
                    >
                        {{ data.lead.fullName || 'Unbekannt' }} ({{ data.lead.customerNumber || '-' }})
                    </th>
                </ng-container>
                <ng-container matColumnDef="header-first-row-second-group">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        [attr.colspan]="2"
                    >
                        {{ data.lead.dateFrom | date }} - {{ data.lead.dateTo | date }}
                    </th>
                </ng-container>
                <ng-container matColumnDef="header-first-row-third-group">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        [attr.colspan]="2"
                    >
                        Coach: {{ profile()?.firstName }} {{ profile()?.lastName }}
                    </th>
                </ng-container>

                <ng-container matColumnDef="header-second-row-first-group">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        [style.text-align]="'left'"
                        [attr.colspan]="2"
                    >
                        Modul{{
                            data.lead.topOfferProductNames.length > 1
                                ? ' (' + data.lead.topOfferProductNames.length + ')'
                                : ''
                        }}: {{ data.lead.topOfferProductNames.join(', ') }}
                    </th>
                </ng-container>
                <ng-container matColumnDef="header-second-row-second-group">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        [attr.colspan]="2"
                    ></th>
                </ng-container>
                <ng-container matColumnDef="header-second-row-third-group">
                    <th
                        mat-header-cell
                        *matHeaderCellDef
                        [attr.colspan]="2"
                    >
                        Feld: {{ data.lead.maxNumOfCoachingUnits }}
                    </th>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="[
                        'header-first-row-first-group',
                        'header-first-row-second-group',
                        'header-first-row-third-group',
                    ]"
                    class="background-light"
                ></tr>
                <tr
                    mat-header-row
                    *matHeaderRowDef="[
                        'header-second-row-first-group',
                        'header-second-row-second-group',
                        'header-second-row-third-group',
                    ]"
                ></tr>
                <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumns"
                    class="background-dark"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        } @else {
            <p>Noch keine gültigen Termine vorhanden!</p>
        }
    }
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button
        mat-icon-button
        mat-dialog-close
        cdkFocusInitial
        aria-label="Zurück"
        matTooltip="Zurück"
    >
        <mat-icon>arrow_back</mat-icon>
    </button>

    <button
        mat-icon-button
        (click)="print()"
        aria-label="Drucken"
        matTooltip="Drucken (Derzeitiger Workaround!)"
        [disabled]="coachingUnitsExtended().length === 0"
    >
        <mat-icon>print</mat-icon>
    </button>
</mat-dialog-actions>
