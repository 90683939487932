<mat-tab-group>
    <mat-tab label="Vorgänge">
        <div>
            @if (loadingLeads()) {
                <br />
                <mat-spinner></mat-spinner>
            } @else {
                <app-lead-list [leads]="leads()"></app-lead-list>
            }
            <br />
            <button
                mat-button
                (click)="getLeads()"
                [disabled]="loadingLeads()"
            >
                Vorgänge laden
            </button>
            <br />
            <br />
        </div>
    </mat-tab>
    <mat-tab label="Aufträge">
        <div>
            @if (loadingOrders()) {
                <br />
                <mat-spinner></mat-spinner>
            } @else {
                <app-order-list [orders]="orders()"></app-order-list>
            }
            <br />
            <button
                mat-button
                (click)="getOrders()"
                [disabled]="loadingOrders()"
            >
                Aufträge laden
            </button>
            <br />
            <br />
        </div>
    </mat-tab>
</mat-tab-group>
