import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { mainAppConfig } from './main-app-config';

mainAppConfig();

/**
 * Boots the Angular application with the specified component and configuration.
 *
 * @param AppComponent - The root component of the application.
 * @param appConfig - The configuration for the application.
 */
bootstrapApplication(AppComponent, appConfig)
    .then(() => {
        registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    })
    .catch((error) => {
        console.error(error);
        throw error;
    });
