import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirmation-dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatIconModule, MatTooltipModule],
})
export class ConfirmationDialog {
    readonly dialogRef = inject(MatDialogRef<ConfirmationDialog>);
    public readonly cancelButton: string = 'Abbrechen';

    public title = 'CONFIRM';
    public text: string = 'WILLST DU CONFIRM?';
    public successButton: string = 'CONFIRMED';
}
