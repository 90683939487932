<mat-toolbar role="heading" aria-level="1">
    <div class="container">
        <div class="row">
            <div class="col-auto">
                <img
                    src="/logo-light.svg"
                    alt="GFN Logo"
                    height="40"
                    width="106"
                    [title]="title"
                />
            </div>
            <div
                class="col-auto"
                [class.d-none]="currentUser()"
                [class.d-md-inline]="currentUser()"
            >
                <span>{{ title }}</span>
            </div>
            <div class="col"></div>
            @if (currentUser()) {
                <div class="col-auto d-none d-md-inline">
                    <span>{{
                        currentUser()?.displayName ||
                            currentUser()?.email ||
                            UNKNOWN_TEXT
                    }}</span>
                </div>
                <div class="col-auto">
                    <button
                        mat-icon-button
                        aria-label="Profil"
                        title="{{
                            currentUser()?.displayName ||
                                currentUser()?.email ||
                                UNKNOWN_TEXT
                        }}"
                        (click)="openProfile()"
                    >
                        <mat-icon>person</mat-icon>
                    </button>
                    <button mat-icon-button (click)="logout()">
                        <mat-icon>logout</mat-icon>
                    </button>
                </div>
            }
        </div>
    </div>
</mat-toolbar>

<main class="container main-container">
    <router-outlet />
</main>

<div class="footer">
    <span class="footer-link imprint" title="Impressum" (click)="openImprint()"
        >Impressum</span
    >
    <span>&middot;</span>
    <span
        class="footer-link privacy-policy"
        title="Datenschutzhinweise"
        (click)="openPrivacyPolicy()"
        >Datenschutzhinweise</span
    >
</div>
