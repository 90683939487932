import { ChangeDetectionStrategy, Component, inject, OnDestroy, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { Profile } from '@lead-in/core';
import {
    getAdditionalUserInfo,
    getAuth,
    indexedDBLocalPersistence,
    OAuthProvider,
    onAuthStateChanged,
    signInWithPopup,
    Unsubscribe,
    User,
    UserCredential,
} from 'firebase/auth';
import { doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';
import { AuthService, NotificationService } from '@app/shared';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatProgressSpinnerModule],
})
export class LoginComponent implements OnDestroy {
    private readonly notificationService = inject(NotificationService);
    private readonly router = inject(Router);
    private readonly authService = inject(AuthService);
    private readonly microsoftTenant = '24bb5161-da2d-4920-9a51-fc3dfbfc7ba0';

    private readonly auth = getAuth();
    private readonly unsubscribeOnAuthStateChanged: Unsubscribe | null = onAuthStateChanged(this.auth, async (user) => {
        if (user) {
            await this.updateProfile(user)
                .then(() => {
                    this.router.navigate(['/main']);
                })
                .catch((error) => {
                    this.notificationService.error(`${error}`);
                    this.loading.set(false);
                });
        } else {
            this.loading.set(false);
        }
    });
    private unsubscribeOnSnapshot: Unsubscribe | null = null;
    private isNewUser: boolean | null = null;

    readonly loading = signal(true);

    /**
     * Initiates the login process using Microsoft as the authentication provider.
     */
    async login(): Promise<void> {
        this.loading.set(true);
        this.auth
            .setPersistence(indexedDBLocalPersistence)
            .then(async () => {
                const provider = new OAuthProvider('microsoft.com');
                provider.setCustomParameters({
                    tenant: this.microsoftTenant,
                    prompt: 'select_account',
                });

                return signInWithPopup(this.auth, provider)
                    .then(async (userCredential: UserCredential) => {
                        /**
                         * Maybe we need to fill some information for the microsoft register process.
                         * TODO: Should be refactored or not, it works... :-)
                         */
                        if (userCredential.providerId === 'microsoft.com') {
                            this.isNewUser = getAdditionalUserInfo(userCredential)?.isNewUser === true;
                            const operation = this.isNewUser ? 'Registered' : 'Logged in';
                            console.log(`${operation} via Microsoft Account: `, userCredential);
                            return userCredential;
                        }

                        throw new Error('Ein unerwarteter Fehler ist aufgetreten.');
                    })
                    .catch((error) => {
                        console.log('Error - Login: ', error);
                        this.loading.set(false);
                        this.notificationService.error(`${error}`);
                    });
            })
            .catch((error) => {
                console.log('Error - Setting Persistence: ', error);
            });
    }

    ngOnDestroy(): void {
        if (this.unsubscribeOnAuthStateChanged) {
            this.unsubscribeOnAuthStateChanged();
        }
        if (this.unsubscribeOnSnapshot) {
            this.unsubscribeOnSnapshot();
        }
    }

    /**
     * Updates the user profile with the provided user credential if the user is new or not unlocked for Coach-Central.
     * @param userCredential - The user credential to update the profile with.
     */
    private async updateProfile(user: User): Promise<unknown> {
        const db = getFirestore();
        const userRef = doc(db, 'users', user.uid);

        return new Promise((resolve, reject) => {
            let resolveOnce = (userDoc: Profile) => {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                resolveOnce = () => {};
                if (this.unsubscribeOnSnapshot) {
                    this.unsubscribeOnSnapshot();
                }
                resolve(userDoc);
            };

            this.unsubscribeOnSnapshot = onSnapshot(
                userRef,
                async (docSnap) => {
                    const user = docSnap.data() as Profile;
                    if (user !== undefined) {
                        if (this.isNewUser === true || user.useCoachCentral == null || user.useCoachCentral === false) {
                            this.isNewUser = false;
                            await updateDoc(userRef, { useCoachCentral: true });
                        }
                        if (user.inactive === true) {
                            this.authService.logout();
                            reject(new Error('Ihr Konto ist deaktiviert.'));
                        }

                        resolveOnce(user);
                    }
                },
                reject
            );
        });
    }
}
