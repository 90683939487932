import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatError } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CoachingUnitListComponent,
    DynamicFormControlComponent,
    FormFieldListComponent,
    ProcessViewDialogData,
    ProcessViewComponent,
} from '@app/components';
import { ApiService, buildFormGroup, NotificationService } from '@app/shared';
import { CoachCentralDetailLead, isValidUrl } from '@lead-in/core';
import { distinctUntilChanged } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-lead-details',
    templateUrl: './lead-details.component.html',
    styleUrl: './lead-details.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CoachingUnitListComponent,
        CommonModule,
        DynamicFormControlComponent,
        FormFieldListComponent,
        FormFieldListComponent,
        MatButtonModule,
        MatError,
        MatExpansionModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
    ],
})
export class LeadDetailsComponent {
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly api = inject(ApiService);
    private readonly notificationService = inject(NotificationService);
    private readonly dialog = inject(MatDialog);

    readonly lead = signal<CoachCentralDetailLead | null>(null);
    readonly loading = signal(false);
    readonly saving = signal(false);
    readonly sendingHelpMail = signal(false);

    readonly masterDataForm = computed(() => buildFormGroup(this.lead()?.masterDataFields ?? []));
    readonly customerDataSections = computed(() =>
        (this.lead()?.customerDataSections ?? []).map((s) => ({
            id: s.id,
            name: s.name,
            form: buildFormGroup(s.formFields),
        }))
    );
    readonly leadDataCategories = computed(() =>
        (this.lead()?.leadDataCategories ?? []).map((c) => ({
            id: c.id,
            name: c.name,
            sections: c.formFieldSections.map((s) => ({
                id: s.id,
                name: s.name,
                form: buildFormGroup(s.formFields),
            })),
        }))
    );

    validParticipantFileUrl = signal(true);

    constructor() {
        this.activatedRoute.paramMap
            .pipe(
                distinctUntilChanged((prev, curr) =>
                    ['customerId', 'leadId'].every((param) => prev.get(param) === curr.get(param))
                ),
                takeUntilDestroyed()
            )
            .subscribe((paramMap) => {
                if (paramMap.has('customerId') && paramMap.has('leadId')) {
                    this.getLead(paramMap.get('customerId')!, paramMap.get('leadId')!);
                }
            });
    }

    back(): void {
        this.router.navigate(['/main']);
    }

    getLead(
        customerId = this.activatedRoute.snapshot.paramMap.get('customerId'),
        leadId = this.activatedRoute.snapshot.paramMap.get('leadId')
    ): void {
        if (typeof customerId !== 'string' || typeof leadId !== 'string') {
            console.error('customerId or leadId is null');
            return;
        }
        this.loading.set(true);
        this.api
            .leadRead({ customerId, leadId })
            .then((response) => {
                this.lead.set(response.lead);
            })
            .finally(() => {
                this.loading.set(false);
            });
    }

    saveLead(): void {
        const customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
        const leadId = this.activatedRoute.snapshot.paramMap.get('leadId');
        if (typeof customerId !== 'string' || typeof leadId !== 'string') {
            console.error('customerId or leadId is null');
            return;
        }
        this.saving.set(true);
        this.api
            .leadUpdate({
                customerId,
                leadId,
                masterDataFields: this.masterDataForm().value,
                customerDataFields: this.customerDataSections().reduce(
                    (allFields, section) => ({ ...allFields, ...section.form.value }),
                    {}
                ),
                leadDataFields: this.leadDataCategories().reduce(
                    (allFields, category) => ({
                        ...allFields,
                        ...category.sections.reduce(
                            (sectionFields, section) => ({ ...sectionFields, ...section.form.value }),
                            {}
                        ),
                    }),
                    {}
                ),
            })
            .finally(() => {
                this.saving.set(false);
            });
    }

    openParticipantFileUrl(participantFileUrl: string): void {
        this.validParticipantFileUrl.set(isValidUrl(participantFileUrl));
        if (this.validParticipantFileUrl()) {
            this.openUrlInNewWindow(participantFileUrl);
        }
    }

    sendLeadHelpMail(customerId: string, leadId: string): void {
        this.sendingHelpMail.set(true);
        this.api
            .leadHelpMailSend({ customerId, leadId })
            .then(() => this.notificationService.info('Die Hilfeanfrage wurde gesendet.'))
            .catch(() => this.notificationService.error('Die Hilfeanfrage konnte nicht gesendet werden.'))
            .finally(() => this.sendingHelpMail.set(false));
    }

    openUrlInNewWindow(url: string): void {
        window.open(url, '_blank', 'noopener');
    }

    openProcessView(lead: CoachCentralDetailLead): void {
        const data: ProcessViewDialogData = { lead: lead };
        const dialogRef = this.dialog.open(ProcessViewComponent, {
            data,
            height: '100vh',
            width: '90vw',
            maxWidth: '90vw',
        });

        dialogRef.afterClosed().subscribe(() => {
            console.log('The dialog was closed');
        });
    }
}
