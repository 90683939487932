import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { LeadListComponent, OrderListComponent } from '@app/components';
import { ApiService } from '@app/shared/api/api.service';
import { CoachCentralListLead, CoachCentralOrder } from '@lead-in/core';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrl: './main.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LeadListComponent,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        OrderListComponent,
        RouterModule,
    ],
})
export class MainComponent {
    private readonly api = inject(ApiService);

    readonly leads = signal<CoachCentralListLead[] | null>(null);
    readonly loadingLeads = signal(false);
    readonly orders = signal<CoachCentralOrder[] | null>(null);
    readonly loadingOrders = signal(false);

    constructor() {
        this.getLeads();
        this.getOrders();
    }

    getLeads(): void {
        this.loadingLeads.set(true);
        this.api
            .leadsReads()
            .then((response) => {
                this.leads.set(response.leads);
            })
            .finally(() => {
                this.loadingLeads.set(false);
            });
    }

    getOrders(): void {
        this.loadingOrders.set(true);
        this.api
            .ordersRead()
            .then((response) => {
                this.orders.set(response.orders.toSorted((a, b) => a.numDate - b.numDate));
            })
            .finally(() => {
                this.loadingOrders.set(false);
            });
    }
}
