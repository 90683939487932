import { inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    private readonly router = inject(Router);

    canActivate(): boolean | Observable<boolean> | Promise<boolean> {
        return new Promise((resolve) => {
            onAuthStateChanged(getAuth(), (user) => {
                if (user) {
                    resolve(true);
                } else {
                    this.router.navigate(['/login']);
                    resolve(false);
                }
            });
        });
    }
}
