import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getAuth } from 'firebase/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly router = inject(Router);

    private readonly auth = getAuth();

    logout() {
        this.auth
            .signOut()
            .then(() => this.router.navigate(['/login']))
            .catch((error) => {
                console.log('Error - Logout: ', error);
            });
    }
}
